/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/

/* helper
----------------------------------------------------------------------------------------------*/
@import "../milligram/color";
@import "../component/layout";
@import "../component/banner-page";
@import "../component/accordion";
@import "../pages/general";

/* Maintenan ce Style
----------------------------------------------------------------------------------------------*/
body.under-maintenance {
    header {
        .main-header {
            display: none;
        }
        .ico-search, .right-top-header{
            opacity: 0; visibility: hidden;
        }
    }
}
body.thanks-page {
    footer {display: none;}

}
.middle.maintenance {
    padding: 0;
}
.misc {
    background: #1F1F1F;
}
.middle.thanks {
     padding-bottom: 30px; overflow: auto;
    .main-content {
        height: calc(100vh - 158px); 
        .wrapper {
            display: flex; align-items: center; height: 100%;
        }
    }
}
.thanks-content {
    width: 600px; margin: auto; text-align: center; padding: 30px 0;
    h2 {font-weight: 400;}
    .red {color: $color-primary;}
    figure {
        display: block; margin: 30px 0;
    }
    .box-white {
        background: $color-initial; padding: 24px 50px; margin-bottom: 30px;
        .data {font-size: .875rem; margin: 0;}
    }
}
.middle.tnc {
    .main-content {
        padding-top: 30px; background: $color-initial;
    }
}
.nav-tnc {
    background: linear-gradient(180deg, #FFFFFF 50%, #ECECEC 83.57%);
    ul {
        margin: 0; display: flex; flex-wrap: nowrap; overflow: auto;
        li {
            margin: 0; flex: none; display: flex; align-items: center;
            a {
                display: block; align-items: flex-end; text-align: center; padding: 20px 30px 15px; position: relative; color: $color-grey;
                transition: all ease-out 0.3s; max-width: 203px; height: 100%;
                &:after {
                    content: ''; height: 3px; width: 0; position: absolute; bottom: 0;
                    left: 0;right: 0; top: auto; transition: all ease-out 0.3s; background: $color-primary;
                }
                &:hover,&.active {
                    transition: all ease-out 0.3s; color: $color-secondary;
                    &:after {
                        transition: all ease-out 0.3s; width: 100%;
                    }
                }
            }
        }
    }
}
.content-tnc {
    background: $color-initial; padding-bottom: 40px;
    .desc-tnc {
        display: none; padding: 20px;
    }
    .accordion {
        .list-accordion {
            .title-accordion {
                padding: 15px 10px; padding-right: 30px;
                &:after,&:before {width: 12px; height: 2px; right: 10px;}
                &:hover {
                    background: #f3f3f3;
                }
            }
            .desc-accordion {
                padding: 20px;
                h3 {
                    font-weight: 400;
                    a {text-decoration: underline;}
                }
            }
            &.active {
                .title-accordion {
                    background: #f3f3f3;
                }
            }
        }
    }
}

@media all and (max-width: 1340px) {
    

}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
    .nav-tnc {
        ul {
            li {
                a {
                    font-size: .875rem; padding: 20px 20px 15px;
                }
            }
        }
    }
    .content-tnc {
        .accordion {
            .list-accordion {
                .desc-accordion {
                    h3 {font-size: 1.5rem;}
                }
            }
        }
    }

    .middle.thanks {
       .main-content {
           height: calc(100vh - 120px); 
           height: calc((var(--vh, 1vh) * 100) - 120px);
       }
    }

    .thanks-content {
       h2 {font-size: 1.75rem;}
    }

    
    
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

    .content-tnc {
        .desc-tnc {
            padding: 24px 0;
        }
        .accordion {
            .list-accordion {
                .desc-accordion {
                    h3 {font-size: 1.25rem;}
                }
            }
        }
    }

    .middle.thanks {
        .main-content {
            height: calc(100vh - 105px); 
            height: calc((var(--vh, 1vh) * 100) - 105px);
        }
    }
    .thanks-content {
        h2 {font-size: 1.5rem;}
    }
    
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
    .nav-tnc {
        ul {
            li {
                a {
                    font-size: .75rem; padding: 16px 16px 12px; max-width: 150px;
                }
            }
        }
    }
    .content-tnc {
        .desc-tnc {
            padding: 16px 0;
        }
    }
    .middle.thanks {
        .main-content {
            height: calc(100vh - 48px); 
            height: calc((var(--vh, 1vh) * 100) - 48px);
        }
    }
    .thanks-content {
        h2 {font-size: 1.25rem; margin-bottom: 15px;}
    }
   
}



@media all and (max-width: 639px) {

   
}



@media all and (max-width: 350px) {

}