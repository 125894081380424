/* Accordion Style
----------------------------------------------------------------------------------------------*/
.data-sheet {
    color: #fff;
    margin: auto;
    width: 886px;
    .list-data-sheet {
        .item {
            display: flex; justify-content: space-between; align-items: center; padding: 24px 0; border-bottom: 1px solid rgba(255,255,255,0.6);
            &:first-child {
                border-top: 1px solid rgba(255,255,255,0.6);
            }
            h4 {
                font-weight: 400; font-size: 1.25rem; margin: 0; display: flex; align-items: center;
                a {display:  block; width: 24px; height: 24px; margin-left: 5px;}
            }
            .right-box {
                min-width: 230px;font-size: 1.25rem; text-align: center; padding: 24px 0; margin: -24px 0; height: 76px;
                background: $color-grey; line-height: 1.35;
                .dot {
                    width: 8px; height: 8px; background: $color-initial; border-radius: 50%; display: block; margin: auto;
                    transform: translateY(100%);
                    &.black {
                        background: $color-secondary;
                    }
                }
            }
        }
    }
}
.accordion {
    position: relative;
    .list-accordion {
        padding: 0 ;border-bottom: 1px solid $color-grey;
        .title-accordion {
            padding: 25px 0; cursor: pointer; padding-right: 30px; position: relative;transition: all ease-out 0.3s;
            h5 {color: $color-secondary; margin: 0; font-size: 1.25rem; font-weight: 700;}
            h4 {font-size: 1.5rem; margin: 0; font-weight: 400;}
            p {margin: 0; font-weight: 500;}
            &:after,&:before {
                content: ''; width: 18px; height: 3px; position: absolute; right: 4px; top: 0; bottom: 0; margin: auto;
                background: #C5001C; transition: all ease-out 0.3s;
            }
            &:after {transform: rotate(90deg);}
        }
        &.active {
            // box-shadow: 0px 0px 10px rgba(0, 29, 59, 0.075);
            .title-accordion {
                padding: 25px;transition: all ease-out 0.3s; box-shadow: 0px 0px 10px rgba(0, 29, 59, 0.075); background: $color-initial;
                padding-right: 45px;
                &:before {right: 25px;transition: all ease-out 0.3s;}
                &:after {
                    transition: all ease-out 0.3s; opacity: 0;
                }
            }
        }
        .desc-accordion {
            padding: 25px 0 40px; display: none;
            p,ol,ul {
                &:last-child {margin-bottom: 0;}
            }
        }
        &:last-child {margin-bottom: 0;}
    }
    &.data-sheet {
        .list-accordion {
            border-color: rgba(255,255,255,0.6);
            &.active {
                .title-accordion {
                    background: transparent;
                    &:before {right: 4px;}
                }
            }
            .title-accordion {
                padding: 25px 25px 25px 0;
                &:before, &:after { display: none; }
            }
            .desc-accordion {
                display: block;
                padding: 0;
            }
        }
    }
    &.compare-acc {
        .list-accordion {
            margin-bottom: 30px;
            &.active {
                .title-accordion {
                    background: transparent; box-shadow: none; border-bottom: 1px solid $color-grey;
                }
            }
            .desc-accordion {
                padding: 0;
            }
        }
    }
}
.accordion-shipping {
	margin-bottom: 40px;
	.box-acc {
		 margin-bottom: 20px;
         padding: 20px 45px;
         border: 1px solid #8686865d;
		.acc-head {
            padding: 10px 0;
            cursor: pointer;
            position: relative;
            transition: all .2s ease-out; 	
            input {
                margin-bottom: 0;
            }			
			label {
                font-size: 1rem;
                font-weight: normal;
                color: #1F1F1F;
                margin: 0;
            }  
		}
		.acc-body {
			display: none;
			padding-left: 33px;	
            .wrap-radio {
                display: flex;
                justify-content: space-between;
                padding: 10px 0;
                label {
                    display: inline;
                    font-size: 1rem;
                    font-weight: normal;
                    color: #1F1F1F;
                    margin: 0;
                }  
                h6 {
                    font-weight: 700;
                    margin: 0;
                }
            }
			p {
				font-size: 1rem;
			}
		}
		&:last-child {margin-bottom: 0;}
	}
}







/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {

    .accordion {
        .list-accordion {
            .title-accordion {
                padding: 20px 0;
                &:before,&:after {
                    width: 14px; height: 3px;
                }
                h5 {font-size: 1rem;}
                h4 {font-size: 1.125rem;}
            }
            &.active {
                .title-accordion {
                    padding: 20px 25px;
                }
            }
            .desc-accordion {
                .list-data-sheet {
                    .item {
                        padding: 20px 0;
                        h4 {font-size: 1rem;}
                        .right-box {padding: 20px 0; margin: -20px 0; font-size: 1rem;height: 64px;}
                    }
                }
            }
        }
        &.data-sheet {
            .list-accordion {
                .title-accordion {
                    padding: 20px; padding-left: 0px;
                }
                &.active {
                    .title-accordion {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
	.data-sheet .list-data-sheet .item {
        padding: 20px 0;
        h4 {font-size: 1rem;}
        .right-box {padding: 20px 0; margin: -20px 0; font-size: 1rem;height: 64px;}
    }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
    .accordion {
        .list-accordion {
            .title-accordion {
                h4 {font-size: 1rem;}
            }
            .desc-accordion {
                .list-data-sheet {
                    .item {
                        h4 {font-size: .75rem;}
                        .right-box {
                            min-width: 168px;
                        }
                    }
                }
            }
        }
    }
    .data-sheet .list-data-sheet .item {
        h4 {font-size: .75rem;}
        .right-box {
            min-width: 168px;
        }
    }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
    .accordion {
        .list-accordion {
            .title-accordion {
                padding: 16px 0;
                h5 {
                    font-size: .875rem;
                }
                h5,h4,p {
                    padding-right: 25px;
                }
                &:before,&:after {
                    width: 10px; height: 2px;
                }
                
            }
            &.active {
                
                .title-accordion {
                    padding: 16px 10px;
                    &:before {right: 10px;}
                }
            }
            .desc-accordion {
                .list-data-sheet {
                    .item {
                        padding: 14px 0;
                        .right-box {min-width: 125px; font-size: .875rem; margin: -14px 0; height: 52px;}
                    }
                }
            }
        }
        &.data-sheet {
            .list-accordion {
                &.active {
                    .title-accordion {padding-left: 0;}
                }
            }
        }
    }
}

@media all and (max-width: 639px) {
    .accordion-shipping {
        .box-acc {
            margin: 20px 0;
            padding: 10px 18px;
            .acc-head {
                label {
                    font-size: 0.875rem;
                }
            }
            .acc-body {
                padding-left: 27px;
                .wrap-radio {
                    flex-direction: column;
                    padding: 5px 0;
                    label {
                        font-size: 0.875rem;
                    }
                    h6 {
                       padding-left: 30px;
                       font-size: 0.875rem;
                    }
                    &:last-child {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 440px) {
    .accordion {
        .list-accordion {
            .desc-accordion {
                .list-data-sheet {
                    .item {
                        flex-direction: column;
                        h4 {width: 100%; padding-bottom: 10px;}
                        .right-box {
                            width: 100%; margin-top: 0; height: auto; padding: 10px 0; min-height: 38px;
                            .dot {
                                
                                height: 6px; width: 6px; transform: translate(100%); position: relative; top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .data-sheet .list-data-sheet .item {
        flex-direction: column;
        h4 {width: 100%; padding-bottom: 10px;}
        .right-box {
            width: 100%; margin-top: 0; height: auto; padding: 10px 0; min-height: 38px;
            .dot {
                height: 6px; width: 6px; transform: translate(100%); position: relative; top: 5px;
            }
        }
    }
}