
/* Banner Style
----------------------------------------------------------------------------------------------*/
.banner-page {
    position: relative;
    figure {
        position: relative;
        img {display: block; width: 100%;}
    }
    .caption-banner {
        position: absolute; width: 820px; left: 0; right: 0; top: 50%; transform: translateY(-50%);
        margin: auto; text-align: center; color: $color-initial;
        h1 {
            font-weight: 300;
            img {display: inline-block; vertical-align: middle;}
        }
        p {font-size: 1.5rem; font-weight: 300; line-height: 1.4;}
        .banner-button {
            a {margin: 0 8px;}
        }
        &.contact {
            color: $color-secondary;
            .button {margin-top: 40px;}
        }
        &.about,
        &.news {
            max-width: 1440px; left: 0; right: 0; padding: 0 56px; width: auto; top: auto;
            transform: translate(0);
            bottom: 50px; text-align: left;
            h1 {
                font-weight: 700;
            }
        }
        &.news {
            h1 {
                font-size: 2.625rem;
                max-width: 90%;
            }
        }
        &.right-full {
            width: 506px; padding: 45px; right: 0; top: 0; bottom: 0; background: rgba(255,255,255,.8);
            transform: translateY(0); left: auto; color: $color-secondary; text-align: left; display: flex;
            align-items: center;
            p {font-size: 1rem;}
            .box-c-19,.box-c {
                padding: 15px; font-size: .875rem; color: $color-initial; display: block; background: $color-secondary;
                line-height: 1.2; margin-bottom: 8px;
            }
            .box-c {display: inline-block;}
        }
    }
    &.half-img {
        display: flex;background: $color-secondary;
        figure {
            width: 50%; order: 2;-webkit-order:2;
        }
        .caption-banner {
            width: 50%;order: 1;-webkit-order:1; position: relative;
             top: 0; transform: translateY(0);
             .half-caption {
                 width: 455px; text-align: left; margin: auto;
                 p {font-size: 1rem; line-height: 1.5;}
             }
        }
    }
    &.under {
        .caption-banner {
            width: 1100px;
            h1 {margin-bottom: 40px;}
        }
    }
    &.full-100vh {
        figure {
            height: calc(100vh - 118px);
            min-height: 500px;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }


}

@media all and (max-width: 1340px) {
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
    .banner-page {
        .caption-banner {
            width: 720px;
            h1{
                font-size: 2rem;
                img {width: 94px;}
            }
            p {font-size: 1.125rem;}
            &.contact {
                .button {margin-top: 20px;}
            }
            &.news {
                h1 {
                    font-size: 2rem;
                }
            }
            &.right-full {
                padding: 40px; right: 40px; width: 500px;
                h1 {
                    font-size: 1.75rem;
                }
            }
        }
        &.half-img {
            figure {
                min-height: 282px;
                img {
                    height: 100%; width: 100%; object-fit: cover;max-width: none;
                }
            }
            .caption-banner {
                .half-caption {
                    width: 390px;
                    h1 {font-size: 1.75rem;margin-bottom: 12px;}
                }
            }
        }
        &.under {
            .caption-banner {
                width: 560px;
                h1 {margin-bottom: 24px;}
            }
        }
        &.result {
            figure {
                height: 180px;
                img {
                    height: 100%; object-fit: cover;
                }
            }
        }
    }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
    .banner-page {
        .caption-banner {
            width: 600px;
            h1{
                font-size: 1.75rem;
                img {width: 76px;}
            }
            &.contact {
                h1 {margin-bottom: 10px;}
                .button {margin-top: 0px;}
                p {font-size: 1rem;}
            }
            &.news {
                h1 {
                    font-size: 1.75rem;
                    img {width: 76px;}
                }
            }
            &.right-full {
                padding: 32px; right: 32px; width: 400px;
                h1 {
                    font-size: 1.5rem;
                }
                .box-c-19 {
                    line-height: 1.3; padding: 10px 15px;
                }
            }
        }
        &.half-img {
            figure {
                min-height: 250px;
            }
            .caption-banner {
                .half-caption {
                    width: 300px;
                    h1 {font-size: 1.5rem;}
                    p {font-size: .75rem;}
                }
            }
        }
        &.under {
            .caption-banner {
                width: 490px;
                h1 {margin-bottom: 24px;}
            }
        }
    }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
    .banner-page {
        .caption-banner {
            width: auto; max-width: calc(100% - 48px);
            h1{
                font-size: 1.5rem;margin-bottom: 12px;
                img {
                    display: block; margin: 0 auto 15px;
                }
            }

            p {font-size: 1rem;}
            &.about {
                padding: 0 30px; bottom: 30px;
            }
            &.news {
                padding: 0 30px; bottom: 30px;
                h1 {
                    font-size: 1.5rem;margin-bottom: 12px;
                    img {
                        display: block; margin: 0 auto 15px;
                    }
                }
            }
            &.right-full {
                padding: 24px; right: 0; width: 100%; left: 0; top: auto; padding-bottom: 14px; max-width: none;
                h1 {
                    font-size: 1.375rem;
                }
                p {font-size: .875rem; margin-bottom: 14px;}
                .box-c-19,.box-c {
                    padding: 8px 10px; font-size: .75rem;
                }
            }
        }
        figure {
            height: 420px;
            img {
                width: 100%;height: 100%; object-fit: cover;
            }
        }
        &.half-img {
            display: block;
            figure {
                min-height: 0; width: 100%; height: auto;
            }
            .caption-banner {
                position: relative; width: 100%; padding: 24px 0;
                .half-caption {
                    width: 100%;
                    h1 {font-size: 1.375rem;}
                    p {margin: 0;}
                }
            }
        }
        &.contact-banner {
            align-items: center;
            display: flex;
            flex-direction: column;
            figure {
                bottom: 0;
                height: auto;
                left: 0;
                min-height: 250px;
                position: absolute;
                right: 0;
                top: 0;
                img {object-position: 89%;}
            }
            .caption-banner {
                padding: 40px 0;
                position: unset;
                transform: translateY(0);
            }

        }
        &.full-100vh {
            figure {
                height: calc(100vh - 48px);
                height: calc((var(--vh, 1vh) * 100) - 48px);
                min-height: 400px;
            }
        }
    }
}



@media all and (max-width: 639px) {


}



@media all and (max-width: 350px) {

}
